@import "./../../styles/master";

:root {
   --expanded-sidebar: 0;
}

.Sidebar {
    background-color: var(--color_white);
    width: 72px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    bottom: 0;
    transition: all 100ms ease-in;
    z-index: 2;
    position: fixed;
    top: 70px;

    .SidebarSpringLogo {
        display: flex;
        justify-content: center;
        padding-bottom: 48px;
        height: 72px;

        .SidebarSpringLogo__icon {
            transition: none;
            width: 24px;
            height: 24px;
        }
    }

    .SidebarItems {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
        overflow: auto;
        margin: 0;
        padding-bottom: 18px;
        border-right: 1px rgba(var(--color_black_1--rgb), 0.2) solid;
    }

    .SidebarItem {
        display: flex;
        list-style: none;
        min-height: 55px;
        width: 100%;
        align-items: center;
        justify-content: center;
        border-radius: initial;
        font-size: 14px;

        &.active {
            background-color: var(--fontColor_navbar);
        }

        &:hover {
            background-color: var(--fontColor_navbar);
            stroke: var(--color_primary);
            color: var(--color_primary);
            text-decoration: none;
        }

        li {
            display: flex;
            flex-direction: row;
            padding: 15px 0;

            svg {
                width: 24px;
                height: 24px;
                transition: none;
            }
        }
    }

    .ExpandIcon {
        margin-top: 56px;
    }

    .LastItemBottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-top: auto;
        margin-bottom: 50px;
        width: 100%;
    }

    .SidebarItemText {
        white-space: pre;
        margin-left: 10px;
        color: var(--color_gray_1);
    }

    &.expanded {
        width: 232px;

        .SidebarSpringLogo {
            justify-content: left;
            width: 113px;

            a {
                height: 38px;

                .SidebarSpringLogo__text-icon {
                    transition: none;
                    width: 112px;
                }
            }
        }

        .SidebarItems {
            margin: 0;
            align-items: flex-start;

            a {
                justify-content: flex-start;
                padding-left: 24px;
            }
        }

        .SidebarItem {
            margin: 0;
            justify-content: flex-start;
            padding-left: 24px;
        }

        .ExpandIcon {
            margin-top: 56px;
        }
    }

    &.overlay {
        position: fixed;
    }
}